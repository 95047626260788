import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MainLayout from "../layout/MainLayout";
import LoaderComponent from "../../../components/user/LoaderComponent";
import ResourceRefreshBtn from "../../../components/user/ResourceRefreshBtn";
import { searchClients, pbiResourcesRefresh } from "../../../services";
import {
  searchWorkspaces,
  getAllPbiResources,
  searchPbiResources,
} from "../../../services/user";
import { Link, useNavigate } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import {
  setWebsiteName,
  setUserData,
  activeMenu,
  setActiveMenu,
  setClientList,
  setClientGroups,
  setClientPbiResources,
  setClientWorkspaces,
  setWholeData,
  setWholePbiResources,
  setActivityLoader,
  setWidgetDataset,
  setTypewiseResources,
  setSuccesMessage,
  setErrorMessage
} from "../../../store/features/generalSlice";
import MyFavourites from "./MyFavourites";
import ClientWorkspacesAccordian from "./ClientWorkspacesAccordian";
import AllResourcesAccordion from "./AllResourcesAccordion";
import Widget from "./Widget";
import SearchBlack from "../../../assets/theme/images/search-black.png";

import {
  FullScreen2,
  Minimize,
} from "../../../assets/theme/images/icons";

function Dashboard({ instance }) {
  const [data, setData] = useState([]);
  const [accessToken, setAccessToken] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [error, setError] = useState(null);
  const [showWorkspaces, setShowWorkspaces] = useState(false);
  const [showResources, setShowResources] = useState(true);
  const [showLoader, setShowLoader] = useState(true);
  const [searchValue, setSearchValue] = useState();
  const userData = useSelector((state) => state.general.userData);
  const general = useSelector((state) => state.general);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  dispatch(setActiveMenu({ activeMenu: "dashboard" }));
  async function getUserData() {
    let pbUser = false;

    if (!userData) {
      pbUser = await localStorage.getItem("pbUser");

      if (pbUser) {
        pbUser = JSON.parse(pbUser);
        setAccessToken(pbUser.accessToken);
        dispatch(setUserData({ userData: pbUser }));
      }
    }
    return pbUser;
  }
  async function startFetching() {
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    setAlertMessage(localStorage.getItem("alertMessage"));
    localStorage.setItem("alertMessage", "");
    let ignore = false;
    const response = await searchWorkspaces(searchValue, user).catch(
      (error) => {
        if (error.code && error.code == "ERR_NETWORK") {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          //setError(error.response.data.message);
          ignore = true;
          setShowLoader(false);
          navigate("/user/error-page");
        } else if (error.request) {
          ignore = true;
          // The request was made but no response was received
          setError("No response received from the server.");
          setShowLoader(false);
        } else {
          ignore = true;
          // Something happened in setting up the request that triggered an error
          setError("An error occurred while processing the request.");
          setShowLoader(false);
        }
      }
    );

    if (!ignore) {
      //setData(response.data);
      dispatch(setClientWorkspaces({ clientWorkspaces: response.data }));
      setShowLoader(false);
    }
  }
  async function getAllDataset() {
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    setAlertMessage(localStorage.getItem("alertMessage"));
    localStorage.setItem("alertMessage", "");
    let ignore = false;
    const response = await getAllPbiResources("", user).catch((error) => {
      if (error.code && error.code == "ERR_NETWORK") {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        //setError(error.response.data.message);
        ignore = true;
        setShowLoader(false);
      } else if (error.request) {
        ignore = true;
        // The request was made but no response was received
        setError("No response received from the server.");
        setShowLoader(false);
      } else {
        ignore = true;
        // Something happened in setting up the request that triggered an error
        setError("An error occurred while processing the request.");
        setShowLoader(false);
      }
    });

    if (!ignore) {
      //setData(response.data);
      //group by type
      let reportData = response.data.pbiResources;
      const data = reportData.reduce((accumulator, item) => {
        // Get the category of the current item

        const type = item.type;

        // Initialize the category array if not already initialized

        if (!accumulator[type]) {
          accumulator[type] = [];
        }

        // Add the current item to its category array

        accumulator[type].push(item);

        return accumulator;
      }, {});
      dispatch(setWidgetDataset({ widgetDataset: data["Dataset"] }));
      dispatch(setTypewiseResources({ typewiseResources: data }));
      

      //group by workspace
      let workspaceData = response.data.pbiResources;
      const tempData = workspaceData.reduce((accumulator, item) => {
        // Get the category of the current item

        const type = item.workspaceId;

        // Initialize the category array if not already initialized

        if (!accumulator[type]) {
          accumulator[type] = [];
        }

        // Add the current item to its category array

        accumulator[type].push(item);

        return accumulator;
      }, {});
      console.log("tempData", tempData);
      dispatch(setWholePbiResources({ wholePbiResources: tempData }));
      setShowLoader(false);
    }
  }

  function handleShowWorkspaces() {
    setShowWorkspaces(true);
    setShowResources(false);
  }
  function handleShowResources() {
    setShowWorkspaces(false);
    setShowResources(true);
  }
  useEffect(() => {
    let ignore = false;
    getUserData();
    if (!general?.wholePbiResources) {
      startFetching();
    }
    if (!general?.wholePbiResources) {
      getAllDataset();
    }
    dispatch(
      setSuccesMessage({
        succesMessage   : false,
      })
    );
    dispatch(
      setErrorMessage({
        errorMessage   : false,
      })
    );
    

    return () => {
      ignore = true;
    };
  }, []);
  return (
    <MsalProvider instance={instance}>
      <MainLayout>
        <div className="inner clearfix">
          <ResourceRefreshBtn />
          {/* <div className="full-screen-row">
            <a href="#">
              <Minimize/> Minimize
            </a>  
            <a href="#">
              <FullScreen2 /> Full Screen
            </a>  
          </div>   */}
          <h1>Favourites</h1>

          <div className="top-slider clearfix">
            <MyFavourites />
          </div>
          <div className="clients-sec">
            <div className="clients-top">


          <div className="main-title-split">
              <h2>{showResources ? "Resources" : "Workspaces"}</h2>
              {showWorkspaces && (
                  <a
                    href="#"
                    className="ViewType"
                    onClick={() => handleShowResources()}
                  >
                    Resource View
                  </a>
                )}
          </div>



              {alertMessage && (
                <div className="alert alert-success" role="alert">
                  <div className="alert-message">{alertMessage}</div>
                </div>
              )}
              {error && (
                <div className="alert alert-danger" role="alert">
                  <div className="alert-message">{error}</div>
                </div>
              )}
              <div className="filter-cell">
                
                {showResources && (
                  <a
                    href="#"
                    className="ViewType"
                    onClick={() => handleShowWorkspaces()}
                  >
                    Workspace View
                  </a>
                )}
                <div className="search">
                  <img src={SearchBlack} alt="" />
                  <input placeholder="Search" type="text" />
                </div>
                {/* <select name="" id="" className="nice-select">
                  <option value="">Filter</option>
                  <option value="">Filter</option>
                  <option value="">Filter</option>
                </select> */}
              </div>
            </div>
            {showWorkspaces && <ClientWorkspacesAccordian clientId="ll" />}
            {showResources && <AllResourcesAccordion />}
          </div>
        </div>
      </MainLayout>
      <Widget clientId="ll" />
    </MsalProvider>
  );
}

export default Dashboard;

import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MainLayout from "../layout/MainLayout";
import { getPbiResources } from "../../../services";
import { useNavigate, useParams, Link } from "react-router-dom";
import PowerBIEmbedView from "../../../components/admin/PowerBIEmbedView";
import LoaderComponent from "../../../components/admin/LoaderComponent";
import { MsalProvider } from "@azure/msal-react";
import {
  browserName,
  browserVersion,
  osName,
  osVersion,
} from "react-device-detect";
import { models } from "powerbi-client";
import {
  getClient,
  exportResource,
  createActivityLog,
} from "../../../services/user";
import {
  setWebsiteName,
  setActivityLoader,
  setUserData,
  activeMenu,
  setActiveMenu,
  setFullScreen,
  setFullwidth,
  setPowerBIEmbedMobileView,
  setPowerBIEmbedLayoutType,
} from "../../../store/features/generalSlice";
import {
  ExportIcn2,
  DisplayMode,
  FullScreen,
  MobileView,
  Bookmark,
  Refresh,
  Reset,
  FitToPage,
  FitToWidth,
} from "../../../assets/theme/images/icons";

function ClientEmbededView({ instance }) {
  const [data, setData] = useState(false);
  const [bookmarkList, setBookmarkList] = useState(false);
  const [bookmarkListOpen, setBookmarkListOpen] = useState(false);
  const [dropdownToggle, setDropdownToggle] = useState();
  const [dropdownDeviceTypeToggle, setDropdownDeviceTypeToggle] = useState();
  const [clientData, setClientData] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const { clientId, resourceId } = useParams();
  const [showLoader, setShowLoader] = useState(true);
  const [error, setError] = useState(null);
  const [searchValue, setSearchValue] = useState();
  const [embedToken, setEmbedToken] = useState("");

  const userData = useSelector((state) => state.general.userData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  dispatch(setActiveMenu({ activeMenu: "clients" }));
  const general = useSelector((state) => state.general);
  async function getUserData() {
    let pbUser = false;

    if (!userData) {
      pbUser = await localStorage.getItem("pbUser");

      if (pbUser) {
        pbUser = JSON.parse(pbUser);
        setAccessToken(pbUser.accessToken);
        dispatch(setUserData({ userData: pbUser }));
      }
    }
    return pbUser;
  }
  async function startFetching() {
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    setAlertMessage(localStorage.getItem("alertMessage"));
    localStorage.setItem("alertMessage", "");
    let ignore = false;
    const response = await getPbiResources(resourceId, clientId, user).catch(
      (error) => {
        if (error.code && error.code == "ERR_NETWORK") {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          //setError(error.response.data.message);
          ignore = true;
          setShowLoader(false);
          navigate("/error-page");
        } else if (error.request) {
          ignore = true;
          // The request was made but no response was received
          setError("No response received from the server.");
          setShowLoader(false);
        } else {
          ignore = true;
          // Something happened in setting up the request that triggered an error
          setError("An error occurred while processing the request.");
          setShowLoader(false);
        }
      }
    );

    if (!ignore) {
      setData(response.data);
      setShowLoader(false);
    }
  }

  const handleRefresh = async () => {
    try {
      await general?.powerBIEmbedResource.refresh();
    } catch (errors) {
      console.log(errors);
    }
  };
  const handleReset = async () => {
    try {
      await general?.powerBIEmbedResource.reload();
    } catch (errors) {
      console.log(errors);
    }
  };
  const handleToggleFullScreen = () => {
    if (osName.includes("iOS")) {
      dispatch(setFullwidth({ fullwidth: true }));
    }
    setDropdownToggle(false);
    setDropdownDeviceTypeToggle(false);
    dispatch(setFullScreen({ isFullScreen: true }));
    try {
      general?.powerBIEmbedResource.fullscreen();
    } catch (errors) {
      console.log(errors);
    }
  };
  const handleFittoWidth = async () => {
    try {
      if (osName.includes("iOS")) {
        dispatch(setFullwidth({ fullwidth: false }));
      }
      setDropdownToggle(false);
      setDropdownDeviceTypeToggle(false);
      const newSettings = {
        layoutType: models.LayoutType.Custom,
        customLayout: {
          displayOption: models.DisplayOption.FitToWidth,
        },
      };

      await general?.powerBIEmbedResource.updateSettings(newSettings);
    } catch (errors) {
      console.log(errors);
    }
  };
  const handleFittoPage = async () => {
    try {
      if (osName.includes("iOS")) {
        dispatch(setFullwidth({ fullwidth: false }));
      }
      setDropdownToggle(false);
      setDropdownDeviceTypeToggle(false);
      const newSettings = {
        layoutType: models.LayoutType.Custom,
        customLayout: {
          displayOption: models.DisplayOption.FitToPage,
        },
      };

      await general?.powerBIEmbedResource.updateSettings(newSettings);
    } catch (errors) {
      console.log(errors);
    }
  };
  const handleMobileView = async (type) => {
    console.log("Mobile");
    try {
      setDropdownToggle(false);
      setDropdownDeviceTypeToggle(false);
      // // let pages = await general?.powerBIEmbedResource.getPages();
      // // let mobileLayout = pages[0].hasLayout(models.LayoutType.MobilePortrait);
      // console.log(mobileLayout);
      dispatch(
        setPowerBIEmbedLayoutType({
          powerBIEmbedLayoutType: type,
        })
      );
    } catch (errors) {
      console.log(errors);
    }
  };
  const getBookmarks = async () => {
    try {
      let temp = [];
      const bookmarks =
        await general?.powerBIEmbedResource.bookmarksManager.getBookmarks();
      bookmarks.forEach(function (bookmark) {
        const str = bookmark.displayName;
        if (!str.startsWith("AiDiInternalBM-")) {
          temp.push(bookmark);
        }
      });
      setBookmarkList(temp);
      setBookmarkListOpen(true);
    } catch (errors) {
      console.log(errors);
    }
  };
  const applyBookmark = async (name) => {
    try {
      await general?.powerBIEmbedResource.bookmarksManager.apply(name);
      console.log('Bookmark "Q4 2014" applied.');
    } catch (errors) {
      console.log(errors);
    }
  };

  const handleDropdownMenuToggle = () => {
    setDropdownToggle(!dropdownToggle);
    setDropdownDeviceTypeToggle(false);
  };
  const handleDropdownDeviceTypeToggle = () => {
    setDropdownDeviceTypeToggle(!dropdownToggle);
    setDropdownToggle(false);
  };
  const handleDashboardPageView = (type) => {
    setDropdownToggle(false);
    setDropdownDeviceTypeToggle(false);
    dispatch(
      setPowerBIEmbedLayoutType({
        powerBIEmbedLayoutType: type,
      })
    );
  };
  const handleBookmarkListOpen = () => {
    setBookmarkListOpen(false);
  };

  async function exportReport(resourceId) {
    let user = false;
    //setShowLoader(true);
    if (!userData) {
      let pbUser = getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    let params = {
      fileFormat: 1,
      resourceType: "Report",
    };
    exportResource(resourceId, params, user)
      .then((res) => {
        let succesMessage = "Export request completed successfully.";
        localStorage.setItem("alertMessage", succesMessage);
        setAlertMessage(succesMessage);
        addActivityLog("Report exported", data);
        //navigate("/client-list");
      })
      .catch((error) => {
        console.log("error", error);
        setShowLoader(false);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          setError(error.response.data.message);
        } else if (error.request) {
          // The request was made but no response was received
          setError("No response received from the server.");
        } else {
          // Something happened in setting up the request that triggered an error
          setError("An error occurred while processing the request.");
        }
      });
  }
  const clearSearch = () => {
    setSearchValue("");
    startFetching();
  };
  async function addActivityLog(log, responseData, logMessage = null) {
    let user = false;
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    let message = logMessage;
    if (logMessage == null) {
      message =
        log +
        " - : " +
        (responseData.aliasName ? responseData.aliasName : responseData.name);
      console.log("message", message);
    }
    const extra =
      log +
      "Name : " +
      (responseData.aliasName ? responseData.aliasName : responseData.name) +
      " - ID: " +
      responseData.id +
      " - resourceID: " +
      responseData.resourceId;
    let formData = {
      activity: log,
      message: message,
      extra: extra,
    };
    console.log("formData", formData);

    createActivityLog(formData, user)
      .then((res) => {
        console.log(general?.powerBIEmbedLayoutType);
      })
      .catch((e) => {
        if (e?.response) {
          let errorMessage = e.response.message;
        }
      });
  }

  useEffect(() => {
    let ignore = false;
    getUserData();
    startFetching();
    return () => {
      ignore = true;
    };
  }, []);
  return (
    <MsalProvider instance={instance}>
      <MainLayout>
        {showLoader && <LoaderComponent />}
        {!showLoader && (
          <>
            <div className="inner client-creation-pg clearfix fh1">
              <div className="title-top-wraper2 flex-wrap">
                <h1>{data.aliasName ? data.aliasName : data.name}</h1>

                <div className="title-top-right">
                  <ul>
                    {(data.type === "Report" ||
                      data.type === "Paginated Report") && (
                      <li>
                        <a
                          href="javascript:void(0);"
                          onClick={() => exportReport(data.id)}
                        >
                          <ExportIcn2 /> Export
                        </a>
                      </li>
                    )}
                    {data.type === "Dataset" && (
                      <li>
                        <a
                          href="javascript:void(0);"
                          onClick={() => handleToggleFullScreen()}
                        >
                          <FullScreen /> Full screen
                        </a>
                      </li>
                    )}
                    {(data.type === "Tile" || data.type === "Dashboard") && (
                      <li className="dropdown dropdown-s1 ">
                        <a
                          href="javascript:void(0);"
                          className={
                            dropdownToggle
                              ? "dropdown-toggle show"
                              : "dropdown-toggle"
                          }
                          onClick={() => handleDropdownMenuToggle()}
                        >
                          <DisplayMode /> Display Mode
                        </a>
                        <ul
                          className={
                            dropdownToggle
                              ? "dropdown-menu dropdown-menu-end show"
                              : "dropdown-menu dropdown-menu-end"
                          }
                        >
                          <li>
                            <a
                              className="dropdown-item"
                              href="javascript:void(0);"
                              onClick={() => handleToggleFullScreen()}
                            >
                              <FullScreen /> Full screen
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="javascript:void(0);"
                              onClick={() =>
                                handleDashboardPageView("fitToWidth")
                              }
                            >
                              <FitToWidth /> Fit to width
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="javascript:void(0);"
                              onClick={() =>
                                handleDashboardPageView("actualSize")
                              }
                            >
                              <FitToPage /> Actual Size
                            </a>
                          </li>
                        </ul>
                      </li>
                    )}
                    {(data.type === "Report" ||
                      data.type === "Paginated Report") && (
                      <li className="dropdown dropdown-s1 ">
                        <a
                          href="javascript:void(0);"
                          className={
                            dropdownToggle
                              ? "dropdown-toggle show"
                              : "dropdown-toggle"
                          }
                          onClick={() => handleDropdownMenuToggle()}
                        >
                          <DisplayMode /> Display Mode
                        </a>
                        <ul
                          className={
                            dropdownToggle
                              ? "dropdown-menu dropdown-menu-end show"
                              : "dropdown-menu dropdown-menu-end"
                          }
                        >
                          <li>
                            <a
                              className="dropdown-item"
                              href="javascript:void(0);"
                              onClick={() => handleToggleFullScreen()}
                            >
                              <FullScreen /> Full screen
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="javascript:void(0);"
                              onClick={() => handleFittoWidth()}
                            >
                              <FitToWidth /> Fit to width
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="javascript:void(0);"
                              onClick={() => handleFittoPage()}
                            >
                              <FitToPage /> Fit to page
                            </a>
                          </li>
                        </ul>
                      </li>
                    )}
                    {(data.type === "Tile" || data.type === "Dashboard") && (
                      <li className="dropdown dropdown-s1 ">
                        <a
                          href="javascript:void(0);"
                          className={
                            dropdownToggle
                              ? "dropdown-toggle show"
                              : "dropdown-toggle"
                          }
                          onClick={() => handleDropdownDeviceTypeToggle()}
                        >
                          <MobileView /> Device Type
                        </a>
                        <ul
                          className={
                            dropdownDeviceTypeToggle
                              ? "dropdown-menu dropdown-menu-end show"
                              : "dropdown-menu dropdown-menu-end"
                          }
                        >
                          <li>
                            <a
                              className="dropdown-item"
                              href="javascript:void(0);"
                              onClick={() =>
                                handleDashboardPageView("fitToWidth")
                              }
                            >
                              <FullScreen /> Desktop
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="javascript:void(0);"
                              onClick={() =>
                                handleDashboardPageView("oneColumn")
                              }
                            >
                              <MobileView /> Mobile View
                            </a>
                          </li>
                        </ul>
                      </li>
                    )}
                    {(data.type === "Report" ||
                      data.type === "Paginated Report") && (
                      <li className="dropdown dropdown-s1 ">
                        <a
                          href="javascript:void(0);"
                          className={
                            dropdownToggle
                              ? "dropdown-toggle show"
                              : "dropdown-toggle"
                          }
                          onClick={() => handleDropdownDeviceTypeToggle()}
                        >
                          <MobileView /> Device Type
                        </a>
                        <ul
                          className={
                            dropdownDeviceTypeToggle
                              ? "dropdown-menu dropdown-menu-end show"
                              : "dropdown-menu dropdown-menu-end"
                          }
                        >
                          <li>
                            <a
                              className="dropdown-item"
                              href="javascript:void(0);"
                              onClick={() =>
                                handleMobileView(models.LayoutType.Master)
                              }
                            >
                              <FullScreen /> Desktop
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="javascript:void(0);"
                              onClick={() =>
                                handleMobileView(
                                  models.LayoutType.MobilePortrait
                                )
                              }
                            >
                              <MobileView /> Mobile View
                            </a>
                          </li>
                        </ul>
                      </li>
                    )}

                    {(data.type === "Report" ||
                      data.type === "Paginated Report") && (
                      <>
                        <li>
                          <a
                            href="javascript:void(0);"
                            className="bookmarks-sidebtn"
                            onClick={() => getBookmarks()}
                          >
                            <Bookmark /> Bookmarks
                          </a>
                        </li>
                        <li>
                          <a
                            href="javascript:void(0);"
                            onClick={() => handleRefresh()}
                          >
                            <Refresh /> Refresh
                          </a>
                        </li>
                        <li>
                          <a
                            href="javascript:void(0);"
                            onClick={() => handleReset()}
                          >
                            <Reset /> Reset
                          </a>
                        </li>
                      </>
                    )}
                    <li>
                      <Link
                        className="dropdown-item"
                        to={`/client/resource-update/${resourceId}`}
                      >
                        <FitToPage /> Update
                      </Link>
                    </li>
                  </ul>
                </div>

                <div
                  className={
                    bookmarkListOpen ? "sidemenu-s1 open" : "sidemenu-s1"
                  }
                >
                  <div className="smenu-header">
                    <h3>Bookmarks</h3>
                    <button
                      type="button"
                      className="btn-close bookmarks-sidebtn"
                      onClick={() => handleBookmarkListOpen()}
                    ></button>
                  </div>
                  <div className="smenu-body">
                    <ul className="smblinks">
                      {bookmarkList &&
                        bookmarkList.length > 0 &&
                        bookmarkList.map((item) => (
                          <li>
                            <a
                              href="javascript:void(0);"
                              onClick={() => applyBookmark(item.name)}
                            >
                              {item.displayName}
                            </a>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="main-bg-wraper--2 fh2">
                <div className="details-list-wraper">
                  {/* <h4>Company Details</h4> */}
                </div>
                {alertMessage && (
                  <div className="alert alert-success" role="alert">
                    <div className="alert-message">{alertMessage}</div>
                  </div>
                )}
                {error && (
                  <div className="alert alert-danger" role="alert">
                    <div className="alert-message">{error}</div>
                  </div>
                )}

                {data && (
                  <PowerBIEmbedView
                    pbiResource={data}
                    clientData={clientData}
                  />
                )}
              </div>
            </div>
            <main className="content">
              <div className="container-fluid p-0">
                <h1 className="h3 mb-3">Client Workspace</h1>
                {alertMessage && (
                  <div className="alert alert-success" role="alert">
                    <div className="alert-message">{alertMessage}</div>
                  </div>
                )}

                <div className="row">
                  <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                    <div className="card flex-fill">
                      <div className="card-header border-bottom">
                        <h5 className="card-title mb-0">Client Workspace -</h5>
                        <div>
                          <div className="d-flex flex-wrap">
                            <Link
                              to={`/client-resource-update/${clientId}/${resourceId}`}
                              className="btn btn-primary"
                            >
                              Update
                            </Link>
                          </div>
                        </div>
                      </div>
                      <input
                        onChange={(e) => setEmbedToken(e.target.value)} // ... and update the state variable on any edits!
                      />
                      {data && (
                        <PowerBIEmbedView
                          pbiResource={data}
                          clientId={clientId}
                          token={embedToken}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </>
        )}
      </MainLayout>
    </MsalProvider>
  );
}

export default ClientEmbededView;

import {
  routes,
  get,
  post,
  put,
  nonExpGet,
  nonExpPost,
  pbiPost,
  nonExpPut,
} from "./index";

export const createClient = (body, user) => {
  return post(`${routes.createClient}`, { body, user });
};
export const updateClient = (clientId, body, user) => {
  return put(`${"admin/client/" + clientId}`, { body, user });
};
export const searchClients = (searchValue, user) => {
  let urlParams = "";
  if (searchValue !== undefined && searchValue !== "") {
    urlParams = "?name=" + searchValue;
  }
  console.log(searchValue);
  return get(`${routes.searchClients + urlParams}`, { user });
};

export const createPbiConfig = (clientId, body, user) => {
  return post(`${"admin/client/" + clientId + "/pbiConfig"}`, { body, user });
};

export const searchClientUsers = (clientId, user) => {
  return get(`${"admin/client/" + clientId + "/user/search"}`, { user });
};
export const getClientUser = (id, clientId, user) => {
  return get(`${"admin/client/" + clientId + "/user/search?id=" + id}`, {
    user,
  });
};
export const getClient = (clientId, user) => {
  return get(`${"admin/client/" + clientId}`, { user });
};

export const createClientUser = (clientId, body, user) => {
  return post(`${"admin/client/" + clientId + "/user"}`, { body, user });
};
export const pbiResourcesRefresh = (clientId, user, body = {}) => {
  return post(`${"admin/client/" + clientId + "/pbiResources/refresh"}`, {
    body,
    user,
  });
};
export const searchClientWorkspaces = (clientId, user) => {
  return get(
    `${"admin/client/" + clientId + "/pbiResources/workspaces/search"}`,
    { user }
  );
};
export const getClientWorkspace = (workspaceId, clientId, user) => {
  return get(
    `${"admin/client/" + clientId + "/pbiResources/workspaces/" + workspaceId}`,
    { user }
  );
};

export const searchClientSecurityGroups = (clientId, user) => {
  return get(`${"admin/client/" + clientId + "/securityGroup/search"}`, {
    user,
  });
};
export const GetReportPages = (reportId, user) => {
  let urlParams = "";

  return get(
    `${"exp/client/pbiResources/reports/" + reportId + "/pages" + urlParams}`,
    { user }
  );
};
export const GetDashboardTiles = (dashboardId, user) => {
  let urlParams = "";

  return get(
    `${
      "exp/client/pbiResources/dashboards/" + dashboardId + "/tiles" + urlParams
    }`,
    { user }
  );
};

export const createClientSecurityGroup = (clientId, body, user) => {
  return post(`${"admin/client/" + clientId + "/securityGroup"}`, {
    body,
    user,
  });
};
export const createClientUserSecurityGroupLink = (clientId, body, user) => {
  return post(`${"admin/client/" + clientId + "/user/securityGroup"}`, {
    body,
    user,
  });
};
export const createClientWorkspaceSecurityGroupLink = (
  clientId,
  body,
  user
) => {
  return post(
    `${"admin/client/" + clientId + "/workspaces/securityGroup/link"}`,
    { body, user }
  );
  //return pbiPost(`${'pbiResources/workspaces/securityGroup/link'}`, { body, user });
};

export const searchTrace = (body, user) => {
  return get(`${"admin/client/trace/search"}`, { body, user });
};
export const updateClientUser = (clientId, userId, body, user) => {
  return put(`${"admin/client/" + clientId + "/user/" + userId}`, {
    body,
    user,
  });
};

export const uploadClientLogo = (clientId, body, user) => {
  // let contentType =
  //   "multipart/form-data; boundary=<calculated when request is sent>";
  return post(`${"admin/client/" + clientId + "/logo"}`, {
    body,
    user,
  });
};

export const searchClientSubscriptions = (clientId, searchValue, user) => {
  let urlParams = "";
  urlParams = "?clientId=" + clientId;
  if (searchValue !== undefined && searchValue !== "") {
    urlParams += "&id=" + searchValue;
  }
  console.log(urlParams);
  return get(`${"admin/client/subscription/search" + urlParams}`, { user });
};

export const getClientSubscriptions = (clientId, id, user) => {
  let urlParams = "";
  urlParams = "?clientId=" + clientId;
  if (id !== undefined && id !== "") {
    urlParams += "&id=" + id;
  }
  console.log(urlParams);
  return get(`${"admin/client/subscription/search" + urlParams}`, { user });
};

export const searchPbiReports = (clientId, workspaceId, user) => {
  return get(
    `${
      "admin/client/" +
      clientId +
      "/pbiResources/search?type=Report&workspaceId=" +
      workspaceId
    }`,
    { user }
  );
};
export const searchClientPbiResources = (clientId, user) => {
  return get(`${"admin/client/" + clientId + "/pbiResources/search"}`, {
    user,
  });
};

export const searchPbiDashboard = (clientId, workspaceId, user) => {
  return get(
    `${
      "admin/client/" +
      clientId +
      "/pbiResources/search?type=Dashboard&workspaceId=" +
      workspaceId
    }`,
    { user }
  );
};
export const searchPbiDataset = (clientId, workspaceId, user) => {
  return get(
    `${
      "admin/client/" +
      clientId +
      "/pbiResources/search?type=Dataset&workspaceId=" +
      workspaceId
    }`,
    { user }
  );
};

export const createClientSubscription = (clientId, body, user) => {
  return post(`${"admin/client/" + clientId + "/subscription"}`, {
    body,
    user,
  });
};

export const subscriptionTriggerNow = (item, user) => {
  const body = {
    fileFormat: 1,
    resourceType: "Report",
    subscriptionId: item.id,
  };
  return post(
    `${
      "exp/pbiResources/" +
      item.resourceId +
      "/export?clientId=" +
      item.clientId
    }`,
    { body, user }
  );
};

export const searchSecurityGroupUsers = (securityGroupId, user) => {
  return get(`${"admin/client/securityGroup/" + securityGroupId + "/users"}`, {
    user,
  });
};

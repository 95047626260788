import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { searchClients, pbiResourcesRefresh } from "../../services";
import LoaderComponent from "../../components/user/LoaderComponent";
import {
  searchWorkspaces,
  getAllPbiResources,
  searchPbiResources,
} from "../../services/user";
import { Link, useNavigate } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import {
  setWebsiteName,
  setUserData,
  activeMenu,
  setActiveMenu,
  setClientList,
  setClientGroups,
  setClientPbiResources,
  setClientWorkspaces,
  setWholeData,
  setWholePbiResources,
  setActivityLoader,
  setWidgetDataset,
  setTypewiseResources,
  setSuccesMessage,
  setErrorMessage,
} from "../../store/features/generalSlice";

import { Reload2, Minimize } from "../../assets/theme/images/icons";
function ResourceRefreshBtn() {
  const [data, setData] = useState([]);
  const [accessToken, setAccessToken] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [error, setError] = useState(null);
  const [showWorkspaces, setShowWorkspaces] = useState(false);
  const [showResources, setShowResources] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [searchValue, setSearchValue] = useState();
  const userData = useSelector((state) => state.general.userData);
  const general = useSelector((state) => state.general);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  async function getUserData() {
    let pbUser = false;

    if (!userData) {
      pbUser = await localStorage.getItem("pbUser");

      if (pbUser) {
        pbUser = JSON.parse(pbUser);
        setAccessToken(pbUser.accessToken);
        dispatch(setUserData({ userData: pbUser }));
      }
    }
    return pbUser;
  }
  async function startFetching() {
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    setAlertMessage(localStorage.getItem("alertMessage"));
    localStorage.setItem("alertMessage", "");
    let ignore = false;
    const response = await searchWorkspaces(searchValue, user).catch(
      (error) => {
        if (error.code && error.code == "ERR_NETWORK") {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          //setError(error.response.data.message);
          ignore = true;
          setShowLoader(false);
          navigate("/client/error-page");
        } else if (error.request) {
          ignore = true;
          // The request was made but no response was received
          setError("No response received from the server.");
          setShowLoader(false);
        } else {
          ignore = true;
          // Something happened in setting up the request that triggered an error
          setError("An error occurred while processing the request.");
          setShowLoader(false);
        }
      }
    );

    if (!ignore) {
      //setData(response.data);
      dispatch(setClientWorkspaces({ clientWorkspaces: response.data }));
      getAllDataset();
    }
  }
  async function getAllDataset() {
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    setAlertMessage(localStorage.getItem("alertMessage"));
    localStorage.setItem("alertMessage", "");
    let ignore = false;
    const response = await getAllPbiResources("", user).catch((error) => {
      if (error.code && error.code == "ERR_NETWORK") {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        //setError(error.response.data.message);
        ignore = true;
        setShowLoader(false);
      } else if (error.request) {
        ignore = true;
        // The request was made but no response was received
        setError("No response received from the server.");
        setShowLoader(false);
      } else {
        ignore = true;
        // Something happened in setting up the request that triggered an error
        setError("An error occurred while processing the request.");
        setShowLoader(false);
      }
    });

    if (!ignore) {
      //setData(response.data);
      //group by type
      let reportData = response.data.pbiResources;
      const data = reportData.reduce((accumulator, item) => {
        // Get the category of the current item

        const type = item.type;

        // Initialize the category array if not already initialized

        if (!accumulator[type]) {
          accumulator[type] = [];
        }

        // Add the current item to its category array

        accumulator[type].push(item);

        return accumulator;
      }, {});
      dispatch(setWidgetDataset({ widgetDataset: data["Dataset"] }));
      dispatch(setTypewiseResources({ typewiseResources: data }));

      //group by workspace
      let workspaceData = response.data.pbiResources;
      const tempData = workspaceData.reduce((accumulator, item) => {
        // Get the category of the current item

        const type = item.workspaceId;

        // Initialize the category array if not already initialized

        if (!accumulator[type]) {
          accumulator[type] = [];
        }

        // Add the current item to its category array

        accumulator[type].push(item);

        return accumulator;
      }, {});
      console.log("tempData", tempData);
      dispatch(setWholePbiResources({ wholePbiResources: tempData }));
      setShowLoader(false);
    }
  }
  function handleResourceRefresh() {
    startFetching();
    
  }

  return (
    <>
      {showLoader && <LoaderComponent />}
      {!showLoader && (
        <div className="full-screen-row">
          <a onClick={()=>handleResourceRefresh()}>
            <Reload2 /> Refresh Resource Data
          </a>
        </div>
      )}
    </>
  );
}

export default ResourceRefreshBtn;

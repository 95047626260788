import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Favicon from "react-favicon";

import BurggerMenu from "../../assets/theme/images/burgger-menu.png";
import BurggerMenuWhite from "../../assets/theme/images/burgger-menu-white.png";
import { useSelector, useDispatch } from "react-redux";
import {
  setBurggerMenuOpenClose,
  setUserData,
  ClearAll,
} from "../../store/features/generalSlice";
import { setIdToken } from "../../store/features/userSlice";

import { NotiIcon } from "../../assets/theme/images/icons";
import LoggedUser from "../../assets/theme/images/dp.png";
import arrowUser from "../../assets/theme/images/arrow-1.png";
import MainSearch from "../../assets/theme/images/search.png";
import UserAvatar from "../../assets/theme/images/user-avatar.png";

import {
  useMsal,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  MsalProvider,
  useIsAuthenticated,
} from "@azure/msal-react";

function Navbar() {
  const [showProfileMenu, setShowProfileMenu] = useState("");
  const navigate = useNavigate();
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const general = useSelector((state) => state.general);
  const handleLogout = () => {
    instance.logout();
    localStorage.removeItem("msalUser");
    localStorage.removeItem("pbUser");
    dispatch(setUserData({ userData: false }));
    dispatch(setIdToken({ idToken: false }));
    dispatch(ClearAll({ idToken: false }));

    navigate("/");
  };
  function burggerMenuToggle() {
    let temp = general?.burggerMenuOpenClose === false ? true : false;
    dispatch(setBurggerMenuOpenClose({ burggerMenuOpenClose: temp }));
    console.log("burggerMenuOpenClose", general?.burggerMenuOpenClose);
  }
  function handleShowProfileMenu() {
    let temp = showProfileMenu === false ? true : false;
    setShowProfileMenu(temp);
  }

  return (
    <>
      <Helmet>
        <title>{general?.metaTitle ? general?.metaTitle : ""}</title>
        <meta
          name="description"
          content={general?.metaDescription ? general?.metaDescription : ""}
        />
        <meta
          name="keywords"
          content={general?.metaKeywords ? general?.metaKeywords : ""}
        />
        <meta name="author" content="Your Name" />
        <meta
          property="og:title"
          content={general?.metaTitle ? general?.metaTitle : ""}
        />
        <meta
          property="og:description"
          content={general?.metaDescription ? general?.metaDescription : ""}
        />
        <meta
          property="og:image"
          content={general?.metaImage ? general?.metaImage : ""}
        />
        <meta
          property="og:url"
          content={general?.metaUrl ? general?.metaUrl : ""}
        />
        <meta
          name="twitter:title"
          content={general?.metaTitle ? general?.metaTitle : ""}
        />
        <meta
          name="twitter:description"
          content={general?.metaDescription ? general?.metaDescription : ""}
        />
        <meta
          name="twitter:image"
          content={general?.metaImage ? general?.metaImage : ""}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Favicon
        url={general?.favicon ? general?.favicon : ""}
        alertCount="10"
        alertFillColor="white"
        alertTextColor="black"
      />

      <div className="top">
        <div className="cell-one">
          <button
            className="open-close mobBurggerButton"
            onClick={() => burggerMenuToggle()}
          >
            <img src={BurggerMenuWhite} alt="" />
          </button>
          <ul className="menu">
            <li className={general?.activeMenu == "dashboard" ? "active" : ""}>
              <a href="/dashboard">Dashboard</a>
            </li>
          </ul>

          <select name="" id="" className="nice-select">
            <option value="">Dashboard</option>
          </select>
        </div>
        <div className="cell-two">
          {/* <a href="/my-notifications" className="noti">
            <div className="count">{data?.unReadCount}</div>
            <NotiIcon />
          </a> */}
          <div className="loginUser">
            <img
              src={UserAvatar}
              alt=""
              onClick={() => handleShowProfileMenu()}
            />
            <span onClick={() => handleShowProfileMenu()}>
              {general?.userData.email}
            </span>
            <img
              className="arrow"
              src={arrowUser}
              alt=""
              onClick={() => handleShowProfileMenu()}
            />

            {/* <ul className="dropdown-menu show"> */}
            <ul
              className={
                showProfileMenu ? "dropdown-menu show" : "dropdown-menu"
              }
            >
              <li className="name">{general?.userData.email}</li>
              {/* <li>
                <a class="dropdown-item" href="#">
                  Profile
                </a>
              </li>

              <li>
                <a class="dropdown-item" href="#">
                  Settings
                </a>
              </li> */}
              <li>
                <a class="dropdown-item" onClick={handleLogout}>
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
